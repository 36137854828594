import { DateTime } from 'luxon'
import md5 from 'md5'
import { dateTimeFromISO, ms } from 'shared/utils/time'
import { Scope } from './types'

export const SOUND_DURATION = ms(9.6, 'seconds')

export const SCOPE_AVG_STEP_MAPPING: Record<Scope, number> = {
  short: 60, // 1 minute
  mid: 8 * 60, // 8 minutes
  long: 20 * 60, // 20 minutes
}

export function formatInstant(dateTime: DateTime) {
  return dateTime
    .startOf('second')
    .setZone('Europe/Paris')
    .toISO({ suppressMilliseconds: true })
}

export function getSequenceKey(serial: string, instant: string): string {
  // Prevent a '.' before milliseconds, not permitted in firebase keys
  const dateTime = formatInstant(dateTimeFromISO(instant))
  return `${md5(`${serial}/${dateTime}`)}_${serial}-${dateTime}`
}

export function getEventKey(serial: string, eventSeed: number): string {
  // Prevent a '.' before milliseconds, not permitted in firebase keys
  const dateTime = formatInstant(DateTime.fromMillis(eventSeed))
  return `${md5(`${serial}/${dateTime}`)}_${serial}-${dateTime}`
}

export function sortIndexByPriority(priority: number, labelersCount: number) {
  return 10 * priority + labelersCount
}

export interface SerialInstant {
  serial: string
  instant: string
}

export function extractSerialInstant(str: string) {
  const parts = str.split('/')
  if (parts.length !== 2) return undefined
  const [serial, instant] = parts
  if (serial.length !== 16 || isNaN(new Date(instant).getTime()))
    return undefined
  return { serial, instant }
}

export function computePercent(timestamp: number, start: number, end: number) {
  return ((timestamp - start) / (end - start)) * 100
}
