import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { Button } from './components/Button'
import { useAuth } from './hooks/useAuth'
import { Login } from './Login'
import { EventMainPage } from './modules/event/EventPage'
import { SequenceMainPage } from './modules/sequence/SequencePage'
import { Stats } from './Stats'

export function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={
          <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="py-4">Une erreur est survenue</div>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Rafraîchir
            </Button>
          </div>
        }
      >
        <Authentification />
        {(import.meta.env.MODE === 'staging' ||
          import.meta.env.MODE === 'event-staging') && (
          <div className="pointer-events-none fixed bottom-2 right-2 text-lime-300">
            <b>STAGING</b>
          </div>
        )}
      </ErrorBoundary>
    </React.StrictMode>
  )
}

const Authentification: React.FC = () => {
  const { loading, error, data: user } = useAuth()
  const showStats = window.location.href.indexOf('?stats') >= 0

  return (
    <div className="min-h-screen">
      {loading ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          Authentification...
        </div>
      ) : error !== null ? (
        <div className="flex min-h-screen flex-col justify-center text-center">
          {error.message}
        </div>
      ) : user === null ? (
        <Login />
      ) : showStats ? (
        <Stats />
      ) : (
        <AppSelector />
      )}
    </div>
  )
}

const AppSelector: React.FC = () => {
  const project = import.meta.env.VITE_PROJECT_ID

  switch (project) {
    case 'oso-event-staging':
    case 'oso-event':
      return <EventMainPage />
    case 'oso-sequence-staging':
    case 'oso-sequence':
    default:
      return <SequenceMainPage />
  }
}
